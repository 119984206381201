import React from 'react';
import styled from "styled-components";

const DisplayOnWeb = styled.div`
  display: flex;
  align-items: center;
  color: #FFF;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

function UnfortunatelyPage() {
    const appStyle = {
        backgroundColor: 'black',
        minHeight: '100%',
        minWidth: '100vw',
    };

    return (
        <div style={appStyle}>
            <DisplayOnWeb>
                <h1>Нам таааак жаааль</h1>
                <iframe src="//coub.com/embed/4a4aq09i?muted=true&autostart=false&originalSize=false&startWithHD=true"
                        allowFullScreen frameBorder="0" width="640" height="360" allow="autoplay"></iframe>
                <script async src="//c-cdn.coub.com/embed-runner.js"></script>
                <h2>Но вам нужен телефон для просмотра нашего приложения</h2>
            </DisplayOnWeb>
        </div>
    );
}

export default UnfortunatelyPage;
