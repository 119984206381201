import styled from 'styled-components';

const Description = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #FFF;
  opacity: 0.6;
`;

export default Description;
