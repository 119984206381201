import React from 'react';
import Header from "./../components/Header";
import Benefits from "./../components/Benefits";
import CallToAction from "./../components/CallToAction";
import DownloadApp from "./../components/DownloadApp";

function MainPage() {
    const appStyle = {
        backgroundColor: 'black',
        minHeight: '100%',
        minWidth: '100vw',
    };

    return (
        <div style={appStyle}>
            <Header />
            <DownloadApp />
            <Benefits />
            <CallToAction />
        </div>
    );
}

export default MainPage;
