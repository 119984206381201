import React from 'react';
import styled from 'styled-components';
import DesktopVideoAngryManWEBM from './../assets/angry_man_desktop.webm';
import DesktopVideoAngryManMP4 from './../assets/angry_man_desktop.mp4';
import Logo from './../assets/logo.svg';
import Description from "../components/Description";
import ApplicationQRCode from "./../assets/application_qr_code.png"

const StyledVideo = styled.video`
  width: 480px;
  height: 268px;
  border-radius: 32px;
`;

type HeaderVideoProps = {
    webmSrc: string;
    mp4Src: string;
};

const DesktopPageVideo: React.FC<HeaderVideoProps> = ({ webmSrc, mp4Src }) => {
    return (
        <>
            <StyledVideo autoPlay loop muted playsInline>
                <source src={webmSrc} type='video/webm; codecs="vp8, vorbis"' />
                <source src={mp4Src} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                Your browser does not support the video tag.
            </StyledVideo>
        </>
    );
};

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12rem;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #141414;
  border-radius: 32px;
`

const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
  margin: 12rem;
  height: 52px;
  width: 484px;
`;

const CenteredDescription = styled(Description)`
  align-self: center;
`

const MainPageDesktop: React.FC = () => {
    return (
        <PageWrapper>
            <TitleContent>
                <img src={Logo} alt="logo" style={{ height: 52, width: 484 }}/>
                <CenteredDescription>Сервис для поиска новых знакомств внутри айти-компаний будет работать, только, с мобильного телефона</CenteredDescription>
            </TitleContent>
            <VideoContainer>
                <DesktopPageVideo mp4Src={DesktopVideoAngryManMP4} webmSrc={DesktopVideoAngryManWEBM}/>
                <img src={ApplicationQRCode} alt={"QR code"}/>
            </VideoContainer>
        </PageWrapper>
    );
};

export default MainPageDesktop;
