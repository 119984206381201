import React from 'react';
import styled from 'styled-components';
import MainPageDesktop from "./MainPageDesktop";
import MainPageMobile from "./MainPageMobile";

const MobileWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MainPage: React.FC = () => {
    return (
        <>
            <MobileWrapper>
                <MainPageMobile />
            </MobileWrapper>
            <DesktopWrapper>
                <MainPageDesktop />
            </DesktopWrapper>
        </>
    );
};

export default MainPage;
