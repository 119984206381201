import React from 'react';
import styled from 'styled-components';

type ButtonProps = {
    isActive?: boolean;
    icon?: JSX.Element;
    children: React.ReactNode;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
    size?: string;
};

const ButtonContainer = styled.div`

`

const StyledButton = styled.button`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem 1rem;
  height: 3.5rem;
  border-radius: 1000px;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ActiveStyledButton = styled(StyledButton)`
  background: #FFF;
`;

const ActiveStyledButtonContent = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #000;
`;

const DisabledStyledButton = styled(StyledButton)`
  background: rgba(255, 255, 255, 0.08);
`;

const DisabledStyledButtonContent = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #FFF;
  opacity: 0.3;
`;

const Button: React.FC<ButtonProps> = ({
    icon: SvgIcon,
    isActive = true,
    children,
    onClick,
    className,
    style,
    size = "large",
}) => {
    const buttonWidth = size === "large" ? "100%" : size === "medium" ? "50vw" : "20vw";
    return (
        <ButtonContainer style={{
            width: `calc(${buttonWidth} - 2rem)`,
        }}>
        {
            isActive ?
                <ActiveStyledButton onClick={onClick} className={className} style={style}>
                    <ActiveStyledButtonContent>{children}</ActiveStyledButtonContent>
                </ActiveStyledButton>
            :
                <DisabledStyledButton>
                   <DisabledStyledButtonContent>{children}</DisabledStyledButtonContent>
                </DisabledStyledButton>
        }
        </ButtonContainer>
    );
};

export default Button;
