import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBItDMznzxMynfJU3prb1xrdQCGG01YYho",
    authDomain: "yomeet-aac2b.firebaseapp.com",
    projectId: "yomeet-aac2b",
    storageBucket: "yomeet-aac2b.appspot.com",
    messagingSenderId: "652678220600",
    appId: "1:652678220600:web:4fd1c9d487712d36f9cfcd",
    measurementId: "G-EKY1W8E80T"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
