// utils/deviceDetector.ts
const getDeviceType = (): string => {
    const userAgent = window.navigator.userAgent;
    const isAndroid = /Android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

    if (isAndroid) {
        return 'Android';
    } else if (isIOS) {
        return 'iOS';
    } else {
        return 'PC';
    }
};

export default getDeviceType;
