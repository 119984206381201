import React from 'react';
import styled from 'styled-components';
import { TitleH2 } from "./Title";
import Description from "./Description";

const StyledCard = styled.section`
  display: flex;
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 2rem 1rem;
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 0.08);
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
`;

const CardTitle = styled(TitleH2)`
  font-size: 1rem;
  text-align: center;
  padding: 0;
  margin-bottom: 0.5rem;
`;

const CardDescription = styled(Description)`
  font-size: 14px;
  text-align: center;
  padding: 0;
  margin: 0;
`;

type CardProps = {
    title: string;
    description: string;
    icon?: JSX.Element;
    buttons?: JSX.Element[];
};

const Card: React.FC<CardProps> = ({ icon, title, description, buttons }) => {
    return (
        <StyledCard>
            <CardContent>
                {icon && <div>{icon}</div>}
                <CardTitle>{title}</CardTitle>
                <CardDescription>{description}</CardDescription>
                {buttons && (
                    <div>
                        {buttons.map((button, index) => (
                            <React.Fragment key={index}>{button}</React.Fragment>
                        ))}
                    </div>
                )}
            </CardContent>
        </StyledCard>
    );
};

export default Card;
