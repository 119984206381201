import React from 'react';
import styled from 'styled-components';
import headerVideoWEBM from './../assets/screenview.webm';
import headerVideoMP4 from './../assets/screenview.mp4';
import Logo from './../assets/logo.svg';
import Description from "./Description";

const HeaderWrapper = styled.div`
  display: flex;
  left: 0;
  top: -8rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 1rem;
  margin-top: -60px;
`;

const HeaderVideo = styled.div`
  margin-bottom: 3rem;
`;

const HeaderImageShadow = styled.div`
  content: '';
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 24px;
  z-index: 10; 
`;

const HeaderContent = styled.div`

`;

const Header: React.FC = () => {
    return (
        <>
            <HeaderImageShadow />
            <HeaderWrapper>
                <HeaderVideo>
                    <video width="100%" height="auto" autoPlay loop muted playsInline>
                        <source src={headerVideoWEBM} type='video/webm; codecs="vp8, vorbis"'/>
                        <source src={headerVideoMP4} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                        Your browser does not support the video tag.
                    </video>
                </HeaderVideo>
                <HeaderContent>
                    <img src={Logo}  alt="logo" />
                    <Description>Сервис для поиска новых знакомств внутри айти-компаний</Description>
                </HeaderContent>
            </HeaderWrapper>
        </>
    );
};

export default Header;
