import React from "react";
import styled from "styled-components";
import Card from "./Card";
import Icon from "./Icon";
import { AngryHorns, LockCheck, ShieldDone, TapSwipe } from "./../assets/icons";

const BenefitColumn = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem 8rem;
  gap: 8px;
`;

const Benefits: React.FC = () => {
    return (
        <BenefitColumn>
            <Card icon={<Icon icon={LockCheck}/>} title="Только для своих" description="На старте, пользоваться сервисом смогут только сотрудники Ozon и Яндекс" />
            <Card icon={<Icon icon={TapSwipe}/>} title="Около тебя прекрасные люди" description="Найди компанию, чтобы вместе сходить на обед, выпить вина после работы или куда-нибудь сходить" />
            <Card icon={<Icon icon={AngryHorns}/>} title="Уважайте друг-друга" description="Соблюдайте правила нравственного и морального поведения, а также общепринятого комплаенса" />
            <Card icon={<Icon icon={ShieldDone}/>} title="Всё конфиденциально" description="Мы не передаем данные компаниям и третьем лицам. Для своей же безопаности не публикуй и не рассказывай NDA, а так же другую конфиденциальную информацию" />
        </BenefitColumn>
    )
}

export default Benefits;
