import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import UnfortunatelyPage from "./pages/UnfortunatelyPage";
import NotFound from "./pages/NotFoundPage";

function App() {
  const appStyle = {
    backgroundColor: 'black',
    minHeight: '100%',
    minWidth: '100vw',
  };

  return (
      <div style={appStyle}>
          <BrowserRouter>
                  <div id="detail">
                      <Routes>
                          <Route path="/" element={<React.Fragment>
                              <MainPage />
                          </React.Fragment>}/>
                          <Route
                              path="/app"
                              element={<UnfortunatelyPage />}
                          />
                          <Route path="*" element={<NotFound />} />
                      </Routes>
                  </div>
          </BrowserRouter>
      </div>
  );
}

export default App;
