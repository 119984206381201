import styled from 'styled-components';

const TitleH1 = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  color: #FFF;
`;

const TitleH2 = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  color: #FFF;
`;

export { TitleH1, TitleH2};
