import React from 'react';

type SvgIconProps = {
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    size?: number | string;
    color?: string;
    className?: string;
    style?: React.CSSProperties;
};

const Icon: React.FC<SvgIconProps> = ({
                                          icon: SvgIcon,
                                          size = 40,
                                          color,
                                          className,
                                          style,
}) => {
    const iconStyle: React.CSSProperties = {
        display: "inline-block",
        width: size,
        height: size,
        fill: color,
        ...style,
    };

    return <SvgIcon className={className} style={iconStyle} />;
};

export default Icon;
