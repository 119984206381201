import React from "react";
import Button from "./Button";
import styled from "styled-components";

const CallToActionContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
`

const BottomShadow = styled.div`
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 80px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
`

const CenterdCallToAction = styled.div`
  width: 100%;
  bottom: 0;
  position: absolute;
  padding: 1rem 1rem 2rem;
  z-index: 10;
`;

const CallToAction: React.FC = () => {
    return (
        <CallToActionContainer>
            <CenterdCallToAction>
                <Button>У меня есть инвайт</Button>
            </CenterdCallToAction>
            <BottomShadow />
        </CallToActionContainer>
    )
}

export default CallToAction;
