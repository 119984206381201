import React from "react";
import styled from "styled-components";

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  text-align: center;
  color: #FFF;
`;

const NotFoundTitle = styled.h1`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const NotFoundText = styled.p`
  font-size: 1.5rem;
`;

const NotFound: React.FC = () => {
    return (
        <NotFoundWrapper>
            <NotFoundTitle>404</NotFoundTitle>
            <NotFoundText>Oops! Page not found</NotFoundText>
        </NotFoundWrapper>
    );
};

export default NotFound;
