import React from "react";
import styled from "styled-components";
import Button from "./Button";
import Icon from "./Icon";
import { AppleIcon, WebIcon, AndroidIcon } from "./../assets/icons";
import deviceDetector from "../utils/deviceDetector";

const DownloadAppContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`

const DownloadAppBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  overflow-y: scroll;
  margin-bottom: 1rem;
  gap: 0.5rem;
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const ButtonDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
`;

const ButtonTitle = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
`;

const ButtonSubtitle = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

const DownloadApp: React.FC = () => {
    const currentDevice = deviceDetector();

    return (
        <DownloadAppContainer>
        <DownloadAppBlock>
            {
                currentDevice === 'Android'
                    ?
                        <></>
                    :
                        <Button isActive={false}>
                            <ButtonContent>
                                <Icon icon={AppleIcon} size={32}/>
                                <ButtonDescription>
                                    <ButtonTitle>Скоро</ButtonTitle>
                                    <ButtonSubtitle>App Store</ButtonSubtitle>
                                </ButtonDescription>
                            </ButtonContent>
                        </Button>
            }
            {
                currentDevice === 'iOS'
                    ?
                        <></>
                    :
                        <Button isActive={false}>
                            <ButtonContent>
                                <Icon icon={AndroidIcon} size={32}/>
                                <ButtonDescription>
                                    <ButtonTitle>Скоро</ButtonTitle>
                                    <ButtonSubtitle>Google Play</ButtonSubtitle>
                                </ButtonDescription>
                            </ButtonContent>
                        </Button>
            }
            <Button isActive={false}>
                <ButtonContent>
                    <Icon icon={WebIcon} size={32}/>
                    <ButtonDescription>
                        <ButtonTitle>Скоро</ButtonTitle>
                        <ButtonSubtitle>Web и PWA</ButtonSubtitle>
                    </ButtonDescription>
                </ButtonContent>
            </Button>
        </DownloadAppBlock>
        </DownloadAppContainer>
    )
}

export default DownloadApp;
